@import '../../styles/colors';

#gridLoginContainer{
  height: 100vh;
  background-color: black;
  display: flex;
  flex-wrap: nowrap;

  >div{
    flex: 0 0 100%;
    //max-width: 60%;

    &:first-child{
      display: none;
    }

    &:last-child{
      background-color: white;
    }
  }

  >.pic >img{
    width: 100%;
    object-fit: cover;
    object-position: left;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  >.form{
    display: flex;
    justify-content: center;
    align-items: center;
    //box-shadow: -5px 0 5px $color-black-opacity;
    //z-index: 10;
  }

  >.form >div {
    max-width: 300px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  #gridLoginContainer{
    >div{
      flex: 0 0 50%;
      text-align: center;

      &:first-child{
        position: relative;
        display: flex;
        background-color: transparent;
      }
    }
  }
}
