@import '../../styles/colors';

#Input{
  width: 100%;
  padding: 5px 0;
  border-radius: 8px;
  margin: 10px 0;

  >input{
    border: none;
    width: -webkit-fill-available;
    padding: 5px 0;
    background-color: transparent;
    margin: 0 10px;
  }
}

.inputGrey{
  background-color: #dadada;
}

.inputWhite{
  background-color: $color-white;
}
