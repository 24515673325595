@import "../../styles/colors";

#Loading{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  color: white;

  >div {
    &:first-child {
      z-index: 110;
      -webkit-animation:spin 3s linear infinite;
      -moz-animation:spin 3s linear infinite;
      animation:spin 3s linear infinite;
    }
    &:last-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.6;
    }
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
