@import '../../styles/colors';

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Subir um arquivo';
  color: white;
  text-transform: uppercase;
  display: inline-block;
  background-color: $color-blue;
  border-radius: 8px;
  padding: 11px 30px;
  letter-spacing: 1px;
  //height: 38px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: bolder;
  //font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background-color: #256599;
}
