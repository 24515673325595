//@import "./styles/_reset.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,700&display=swap');

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
