@import '../../styles/colors';

#Navbar{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 48px;
  padding: 10px 0;
  align-items: center;
  background-color: white;
  z-index: 100;
  border-bottom: 1px solid $color-grey;

  >.imageContainer{
    flex: 0 0 70%;
  }

  >.imageContainer >img{
    display: none;
    margin: 0 15px;
  }

  >.imageContainer >h1{
    display: block;
    margin: 0 15px;
  }

  >.searchContainer{
    display: none;
  }

  >.searchContainer >div {
    width: 600px;
  }

  >.infosContainer{
    flex: 0 0 30%;
    display: flex;
    justify-content: center;
  }

  >.infosContainer >button {
    display: block;
  }

  >.infosContainer >div{
    display: flex;
    align-items: center;
  }

  >.infosContainer >div >*{
    margin: 0 5px;
  }
}

#UserDrop{
  position: fixed;
  width: 300px;
  //height: 500px;
  top: 30px;
  right: 5px;
  box-shadow: 0 0px 5px #000000ad;
  border-radius: 8px;
  background-color: white;
  padding: 20px;

  >div{
    text-align: center;
  }

  >div >img{
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    margin-top: 20px;
  }
}

#NotificationDrop{
  position: fixed;
  width: 300px;
  height: 400px;
  top: 30px;
  right: 5px;
  box-shadow: 0 0px 5px #000000ad;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  >h1{
    text-align: center;
    color: $color-black-opacity;
  }
}

#SystemChangeDrop{
  position: fixed;
  width: 200px;
  top: 30px;
  right: 5%;
  box-shadow: 0 0px 5px #000000ad;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  >ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  >ul >li {
    font-size: 1.5rem;
    margin: 10px 0;
  }

  >ul >li >a{
    transition: .2s;
    text-decoration: none;
    color: $color-black;
    //margin: 0 15px;

    &:hover{
      cursor: pointer;
      color: $color-blue;
    }
  }

  >ul >li >a >svg {
    margin: 0 15px;
  }

  >ul >li >.active {
    color: $color-blue;
  }
}

@media screen and (min-width: 768px) {
  #Navbar{
    >.imageContainer{
      flex: 0 0 10%;
    }

    >.imageContainer >img{
      display: block;
      width: 124px;
      height: 44px;
    }

    >.imageContainer >h1{
      display: none;
    }

    >.searchContainer{
      display: flex;
      justify-content: flex-end;
      flex: 0 0 60%;
    }

    >.infosContainer{
      flex: 0 0 30%;
      justify-content: flex-end;
    }

    >.infosContainer >div{
      margin-right: 15px;
    }
  }
}
