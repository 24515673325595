$color-black: #424242;
$color-black-opacity: #6d6e70;
$color-grey: #d0d0d0;
$color-grey-light: #e8e8e8;
$color-blue: #24a1dc;
$color-pink: #d82f5a;
$color-white: white;

//ORDEM COLORS
 $color-light-primary: $color-blue;
 $color-light-secondary: rgba(36, 161, 220, 0.2);
 $color-light-text: #0b8bc8;

$color-dark-primary: $color-pink;
$color-dark-secondary: #d82f5a63;
$color-dark-text: #bc1741;