@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,700&display=swap);
html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee; }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

#Input {
  width: 100%;
  padding: 5px 0;
  border-radius: 8px;
  margin: 10px 0; }
  #Input > input {
    border: none;
    width: -webkit-fill-available;
    padding: 5px 0;
    background-color: transparent;
    margin: 0 10px; }

.inputGrey {
  background-color: #dadada; }

.inputWhite {
  background-color: white; }

#Loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  color: white; }
  #Loading > div:first-child {
    z-index: 110;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite; }
  #Loading > div:last-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

#Button {
  width: 100%;
  transition: .3s ease;
  height: 38px;
  border: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 8px;
  color: white;
  font-weight: bolder; }
  #Button:hover {
    cursor: pointer; }

.primary {
  transition: .3s ease;
  background-color: #24a1dc; }
  .primary:hover {
    box-shadow: 0 5px 10px #d0d0d0; }

.secondary {
  transition: .3s ease;
  background-color: white;
  border: 2px solid #24a1dc !important;
  color: #0b8bc8 !important; }
  .secondary:hover {
    box-shadow: 0 5px 10px #d0d0d0; }

.text {
  transition: .2s ease-in-out;
  color: #424242 !important;
  text-transform: none !important;
  font-weight: 100 !important;
  background-color: transparent; }
  .text:hover {
    text-decoration: underline; }

@media (min-width: 576px) {
  #Button {
    max-width: 200px; } }

.fullSize {
  max-width: none !important;
  width: 100%; }

#gridLoginContainer {
  height: 100vh;
  background-color: black;
  display: flex;
  flex-wrap: nowrap; }
  #gridLoginContainer > div {
    flex: 0 0 100%; }
    #gridLoginContainer > div:first-child {
      display: none; }
    #gridLoginContainer > div:last-child {
      background-color: white; }
  #gridLoginContainer > .pic > img {
    width: 100%;
    object-fit: cover;
    object-position: left;
    transform: scaleX(-1); }
  #gridLoginContainer > .form {
    display: flex;
    justify-content: center;
    align-items: center; }
  #gridLoginContainer > .form > div {
    max-width: 300px;
    width: 100%; }

@media screen and (min-width: 768px) {
  #gridLoginContainer > div {
    flex: 0 0 50%;
    text-align: center; }
    #gridLoginContainer > div:first-child {
      position: relative;
      display: flex;
      background-color: transparent; } }

#LoginForm {
  height: 170px; }

#RecoveryForm {
  height: 170px; }

#BigCard {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: white; }

#TitleCards {
  width: 100%;
  height: 50px;
  padding: 0 15px;
  display: flex;
  color: #6d6e70; }
  #TitleCards > * {
    display: none; }
    #TitleCards > *:first-child {
      display: block;
      flex: 0 0 50%; }

@media screen and (min-width: 768px) {
  #TitleCards {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex; }
    #TitleCards > * {
      display: block;
      flex: 0 0 30%; }
      #TitleCards > *:first-child {
        flex: 0 0 30%; }
      #TitleCards > *:last-child {
        flex: 0 0 10%; } }

#SmallCard {
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: white; }
  #SmallCard > div {
    display: flex;
    margin: 0 20px;
    width: 100%;
    align-items: center; }
  #SmallCard > div > * {
    display: none; }
    #SmallCard > div > *:first-child {
      display: inline-flex;
      flex: 0 0 90%;
      justify-content: space-between; }
    #SmallCard > div > *:last-child {
      display: block;
      flex: 0 0 10%; }
  #SmallCard > div > div > h3 {
    font-weight: normal;
    margin-left: 10px; }
  #SmallCard > div > div > .listAction {
    display: flex;
    justify-content: center; }
  #SmallCard > div > div > .listAction > div {
    display: none; }
    #SmallCard > div > div > .listAction > div:first-child {
      display: block;
      width: 19px; }
      #SmallCard > div > div > .listAction > div:first-child:hover {
        color: #F4B606; }
    #SmallCard > div > div > .listAction > div:hover {
      cursor: pointer;
      color: #d82f5a; }

.selected {
  border: 2px solid #24a1dc; }

@media screen and (min-width: 768px) {
  #SmallCard > div > * {
    display: block;
    flex: 0 0 30%; }
    #SmallCard > div > *:first-child {
      flex: 0 0 30%; }
    #SmallCard > div > *:last-child {
      flex: 0 0 10%; }
  #SmallCard > div > div > .listAction > div {
    display: block;
    transition: .2s;
    margin: 0 5px; }
    #SmallCard > div > div > .listAction > div:first-child {
      width: 19px; }
      #SmallCard > div > div > .listAction > div:first-child:hover {
        color: #F4B606; }
    #SmallCard > div > div > .listAction > div:hover {
      cursor: pointer;
      color: #d82f5a; } }

#DeleteUser {
  width: 300px; }
  #DeleteUser > div {
    margin-top: 50px;
    display: flex;
    justify-content: center; }
  #DeleteUser > div > button {
    display: inline-block; }

@media screen and (min-width: 768px) {
  #DeleteUser {
    width: 600px; }
    #DeleteUser > div {
      margin-top: 50px;
      display: flex;
      justify-content: center; }
    #DeleteUser > div > button {
      display: inline-block; } }

#Modal {
  top: 0;
  left: 0;
  z-index: 120;
  position: fixed;
  width: 100vw !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0 !important; }
  #Modal > .modal-overlay {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.69); }
  #Modal > .modal-content {
    background-color: white;
    padding: 20px;
    z-index: 110;
    max-width: 600px;
    max-height: 70vh;
    border-radius: 10px; }
  #Modal > .modal-content > .modal-actions {
    display: block;
    padding: 5px 0; }
  #Modal > .modal-content > .modal-actions > div > h3 {
    margin: 0; }
  #Modal > .modal-content > .modal-actions > div {
    display: inline-block; }
    #Modal > .modal-content > .modal-actions > div:first-child {
      text-align: left;
      width: 80%; }
    #Modal > .modal-content > .modal-actions > div:last-child {
      width: 20%;
      text-align: right;
      font-size: 20px; }
      #Modal > .modal-content > .modal-actions > div:last-child:hover {
        cursor: pointer;
        opacity: 0.8; }
  #Modal > .modal-content > .content {
    overflow-y: auto;
    height: 95%; }

#ButtonIcon {
  border: none;
  background-color: transparent;
  width: 45px;
  height: 45px;
  font-size: x-large; }
  #ButtonIcon:hover {
    cursor: pointer; }

#Navbar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 48px;
  padding: 10px 0;
  align-items: center;
  background-color: white;
  z-index: 100;
  border-bottom: 1px solid #d0d0d0; }
  #Navbar > .imageContainer {
    flex: 0 0 70%; }
  #Navbar > .imageContainer > img {
    display: none;
    margin: 0 15px; }
  #Navbar > .imageContainer > h1 {
    display: block;
    margin: 0 15px; }
  #Navbar > .searchContainer {
    display: none; }
  #Navbar > .searchContainer > div {
    width: 600px; }
  #Navbar > .infosContainer {
    flex: 0 0 30%;
    display: flex;
    justify-content: center; }
  #Navbar > .infosContainer > button {
    display: block; }
  #Navbar > .infosContainer > div {
    display: flex;
    align-items: center; }
  #Navbar > .infosContainer > div > * {
    margin: 0 5px; }

#UserDrop {
  position: fixed;
  width: 300px;
  top: 30px;
  right: 5px;
  box-shadow: 0 0px 5px #000000ad;
  border-radius: 8px;
  background-color: white;
  padding: 20px; }
  #UserDrop > div {
    text-align: center; }
  #UserDrop > div > img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    margin-top: 20px; }

#NotificationDrop {
  position: fixed;
  width: 300px;
  height: 400px;
  top: 30px;
  right: 5px;
  box-shadow: 0 0px 5px #000000ad;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  #NotificationDrop > h1 {
    text-align: center;
    color: #6d6e70; }

#SystemChangeDrop {
  position: fixed;
  width: 200px;
  top: 30px;
  right: 5%;
  box-shadow: 0 0px 5px #000000ad;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  #SystemChangeDrop > ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  #SystemChangeDrop > ul > li {
    font-size: 1.5rem;
    margin: 10px 0; }
  #SystemChangeDrop > ul > li > a {
    transition: .2s;
    text-decoration: none;
    color: #424242; }
    #SystemChangeDrop > ul > li > a:hover {
      cursor: pointer;
      color: #24a1dc; }
  #SystemChangeDrop > ul > li > a > svg {
    margin: 0 15px; }
  #SystemChangeDrop > ul > li > .active {
    color: #24a1dc; }

@media screen and (min-width: 768px) {
  #Navbar > .imageContainer {
    flex: 0 0 10%; }
  #Navbar > .imageContainer > img {
    display: block;
    width: 124px;
    height: 44px; }
  #Navbar > .imageContainer > h1 {
    display: none; }
  #Navbar > .searchContainer {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 60%; }
  #Navbar > .infosContainer {
    flex: 0 0 30%;
    justify-content: flex-end; }
  #Navbar > .infosContainer > div {
    margin-right: 15px; } }

#dashboardAdmin {
  padding: 0 15px; }
  #dashboardAdmin > .main {
    margin-top: 100px; }
  #dashboardAdmin > .main > .title {
    display: flex;
    margin-top: 20px; }
  #dashboardAdmin > .main > .title > div {
    display: none; }
    #dashboardAdmin > .main > .title > div:last-child {
      display: block;
      flex: 0 0 100%; }
  #dashboardAdmin > .main > .title > div > h1 {
    padding: 0 50px;
    margin: 0; }

#FormUser {
  width: 600px; }
  #FormUser > form > .form {
    margin-bottom: 20px; }
  #FormUser > form > .form > input {
    display: block; }
  #FormUser > form > .form > div {
    text-align: center;
    margin: 10px 0; }
  #FormUser > form > .form > div > div {
    display: inline-block; }
  #FormUser > form > div:last-child {
    text-align: center; }

@media screen and (min-width: 768px) {
  #dashboardAdmin {
    display: flex;
    justify-content: center; }
    #dashboardAdmin > .main {
      width: 100%;
      max-width: 1100px;
      margin-top: 100px; }
    #dashboardAdmin > .main > .searchContainer {
      padding: 0 20%; }
    #dashboardAdmin > .main > .title > div {
      display: block;
      color: #424242;
      flex: 0 0 80%; }
      #dashboardAdmin > .main > .title > div:last-child {
        flex: 0 0 20%; } }

.custom-file-input {
  color: transparent; }

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden; }

.custom-file-input::before {
  content: 'Subir um arquivo';
  color: white;
  text-transform: uppercase;
  display: inline-block;
  background-color: #24a1dc;
  border-radius: 8px;
  padding: 11px 30px;
  letter-spacing: 1px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: bolder; }

.custom-file-input:hover::before {
  border-color: black; }

.custom-file-input:active {
  outline: 0; }

.custom-file-input:active::before {
  background-color: #256599; }

#FileCard {
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: white; }
  #FileCard > div {
    display: flex;
    margin: 0 20px;
    width: 100%;
    align-items: center; }
  #FileCard > div > * {
    display: none; }
    #FileCard > div > *:first-child {
      display: block;
      flex: 0 0 80%; }
    #FileCard > div > *:last-child {
      display: block;
      flex: 0 0 20%; }
  #FileCard > div > div > h3 {
    font-weight: normal;
    margin-left: 10px; }
  #FileCard > div > div > .listAction {
    display: flex;
    justify-content: center; }
  #FileCard > div > div > .listAction > div {
    display: block;
    margin: 0 10px; }
    #FileCard > div > div > .listAction > div:first-child {
      display: block;
      width: 19px; }
      #FileCard > div > div > .listAction > div:first-child:hover {
        color: #d82f5a; }
    #FileCard > div > div > .listAction > div:hover {
      cursor: pointer;
      color: #d82f5a; }
  #FileCard > div > div > .listAction > div > a {
    color: #6d6e70; }
    #FileCard > div > div > .listAction > div > a:hover {
      color: #d82f5a; }

.selected {
  border: 2px solid #24a1dc; }

@media screen and (min-width: 768px) {
  #FileCard > div > * {
    display: block; }
    #FileCard > div > *:first-child {
      display: flex;
      flex: 0 0 90%; }
    #FileCard > div > *:last-child {
      flex: 0 0 10%; }
  #FileCard > div > .infos > div {
    flex: 0 0 30%; }
    #FileCard > div > .infos > div:first-child {
      flex: 0 0 40%; }
  #FileCard > div > div > .listAction > div {
    display: block;
    transition: .2s;
    margin: 0 5px; }
    #FileCard > div > div > .listAction > div:first-child {
      width: 19px; }
      #FileCard > div > div > .listAction > div:first-child:hover {
        color: #d82f5a; }
    #FileCard > div > div > .listAction > div:hover {
      cursor: pointer;
      color: #d82f5a; } }

#Ftp > .content {
  padding: 0 15px;
  margin-top: 80px;
  height: 100vh; }

#Ftp > .content > .header {
  display: flex;
  flex-wrap: wrap; }

#Ftp > .content > .header > div {
  flex: 0 0 100%; }

#Ftp > .content > .header > div > h1 {
  margin: 10px; }

#Ftp > .content > .list-files > h1 {
  color: #d0d0d0; }

#ProgressBar[value] {
  transition: .2s;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 250px;
  height: 20px; }
  #ProgressBar[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 5px; }
  #ProgressBar[value]::-webkit-progress-value {
    background-color: #24a1dc;
    border-radius: 5px; }

#CompanyList {
  min-width: 400px;
  width: 100%;
  height: 350px; }
  #CompanyList .list {
    list-style: none;
    margin: 15px 0;
    padding: 0;
    text-align: center; }
  #CompanyList .itemList {
    transition: .2s;
    margin: 10px 0;
    padding: 5px 0;
    font-size: x-large;
    color: #d0d0d0; }
    #CompanyList .itemList:hover {
      cursor: pointer;
      color: #d82f5a; }
  #CompanyList .actived {
    background-color: #24a1dc;
    border-radius: 3px;
    color: white; }

@media screen and (min-width: 768px) {
  #Ftp {
    display: flex;
    justify-content: center; }
    #Ftp > .content {
      width: 100%;
      max-width: 1100px;
      margin-top: 100px; }
    #Ftp > .content > .searchContainer {
      padding: 0 20%; }
    #Ftp > .content > .header {
      flex-wrap: nowrap; }
    #Ftp > .content > .header > div:first-child {
      flex: 0 0 70%; }
    #Ftp > .content > .header > div:last-child {
      flex: 0 0 30%;
      display: flex;
      justify-content: flex-end; } }

#newPassword {
  width: 100%;
  height: 100%; }


