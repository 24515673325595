#Modal{
  top: 0;
  left: 0;
  z-index: 120;
  position: fixed;
  width: 100vw !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0 !important;

  >.modal-overlay{
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.69);
  }

  >.modal-content{
    background-color: white;
    padding: 20px;
    z-index: 110;
    max-width: 600px;
    max-height: 70vh;
    //height: 100%;
    border-radius: 10px;
  }

  >.modal-content >.modal-actions{
    display: block;
    padding: 5px 0;
  }

  >.modal-content >.modal-actions >div >h3{
    margin: 0;
  }

  >.modal-content >.modal-actions >div {
    display: inline-block;

    &:first-child{
      text-align: left;
      width: 80%;
    }

    &:last-child {
      width: 20%;
      text-align: right;
      font-size: 20px;

      &:hover{
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  >.modal-content >.content{
    overflow-y: auto;
    height: 95%;
  }

  //>.modal-content >.modal-actions >div >h3{
  //  font-size: ;
  //}
}
