@import '../../styles/colors';

#TitleCards{
  width: 100%;
  height: 50px;
  padding: 0 15px;
  display: flex;
  color: $color-black-opacity;

  >*{
    display: none;

    &:first-child{
      display: block;
      flex: 0 0 50%;
    }
  }
}

@media screen and (min-width: 768px) {
  #TitleCards{
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex;

    >*{
      display: block;
      flex: 0 0 30%;

      &:first-child{
        flex: 0 0 30%;
      }

      &:last-child{
        flex: 0 0 10%;
      }
    }
  }
}
