@import '../../styles/colors';
@import '../../styles/cards';

#FileCard{
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include card();

  >div{
    display: flex;
    margin: 0 20px;
    width: 100%;
    align-items: center;
  }

  >div >*{
    display: none;

    &:first-child{
      display: block;
      flex: 0 0 80%;
    }

    &:last-child{
      display: block;
      flex: 0 0 20%;
    }
  }

  >div >div >h3{
    font-weight: normal;
    margin-left: 10px;
  }

  >div >div >.listAction{
    display: flex;
    justify-content: center;
  }

  >div >div >.listAction >div {
    display: block;
    margin: 0 10px;
    &:first-child{
      display: block;
      width: 19px;

      &:hover{
        color: #d82f5a;
      }
    }

    &:hover{
      cursor: pointer;
      color: $color-pink;
    }
  }

  >div >div >.listAction >div >a {
    color: $color-black-opacity;

    &:hover{
      color: $color-pink;
    }
  }
}

.selected{
  border: 2px solid $color-blue;
}

@media screen and (min-width: 768px) {
  #FileCard{
    >div >*{
      display: block;
      &:first-child{
        display: flex;
        flex: 0 0 90%;
      }

      &:last-child{
        flex: 0 0 10%;
      }
    }

    >div >.infos >div{
      flex: 0 0 30%;
      &:first-child{
        flex: 0 0 40%;
      }
    }

    >div >div >.listAction >div {
      display: block;
      transition: .2s;
      margin: 0 5px;
      &:first-child{
        width: 19px;

        &:hover{
          color: #d82f5a;
        }
      }

      &:hover{
        cursor: pointer;
        color: $color-pink;
      }
    }
  }
}
