#ButtonIcon{
  border: none;
  background-color: transparent;
  width: 45px;
  height: 45px;
  font-size: x-large;

  &:hover{
    cursor: pointer;
  }
}
