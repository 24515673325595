@import '../../styles/colors';
@import '../../styles/dashboardGrid';

#Ftp{
  >.content{
    padding: 0 15px;
    margin-top: 80px;
    height: 100vh;
  }

  >.content >.header {
    //margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }

  >.content >.header >div {
    flex: 0 0 100%;
  }

  >.content >.header >div >h1{
    margin: 10px;
  }

  //>.content >.list-files {
  //  height: 300px;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //}

  >.content >.list-files >h1 {
    color: $color-grey;
  }
}

#ProgressBar[value] {
  transition: .2s;
  -webkit-appearance: none;
  appearance: none;

  width: 250px;
  height: 20px;

  &::-webkit-progress-bar{
    background-color: #eee;
    border-radius: 5px;
  }

  &::-webkit-progress-value {
    background-color: $color-blue;
    border-radius: 5px;
  }
}

#CompanyList {
  min-width: 400px;
  width: 100%;
  height: 350px;

  .list{
    list-style: none;
    margin: 15px 0;
    padding: 0;
    text-align: center;
  }

  .itemList{
    transition: .2s;
    margin: 10px 0;
    padding: 5px 0;
    font-size: x-large;
    color: $color-grey;

    &:hover{
      cursor: pointer;
      color: $color-pink;
    }
  }

  .actived {
    background-color: $color-blue;
    border-radius: 3px;
    color: white;
  }
}

@media screen and (min-width: 768px) {
  #Ftp{
    display: flex;
    justify-content: center;

    >.content{
      width: 100%;
      max-width: 1100px;
      margin-top: 100px;
    }

    >.content >.searchContainer {
      padding: 0 20%;
    }

    >.content >.header {
      flex-wrap: nowrap;
    }

    >.content >.header >div {
      &:first-child{
        flex: 0 0 70%;
      }

      &:last-child {
        flex: 0 0 30%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
