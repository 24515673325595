@import '../../styles/colors';
@import '../../styles/dashboardGrid';

#dashboardAdmin{
  //@include dashboardMobile();
  padding: 0 15px;

  >.main {
    margin-top: 100px;
  }

  >.main >.title {
    display: flex;
    margin-top: 20px;
  }

  >.main >.title >div{
    display: none;
    &:last-child{
      display: block;
      flex: 0 0 100%;
    }
  }

  >.main >.title >div >h1{
    padding: 0 50px;
    margin: 0;
  }
}

#FormUser{
  width: 600px;
  >form >.form{
    margin-bottom: 20px;
  }

  >form >.form >input{
    display: block;
  }

  >form >.form >div{
    text-align: center;
    margin: 10px 0;
  }

  >form >.form >div >div {
    display: inline-block;
  }

  >form >div {
    &:last-child{
      text-align: center;
    }
  }
}

@media screen and (min-width: 768px) {
  #dashboardAdmin{
    //@include dashboardDesktop();
    display: flex;
    justify-content: center;

    >.main {
      width: 100%;
      max-width: 1100px;
      margin-top: 100px;
    }

    >.main >.searchContainer {
      padding: 0 20%;
    }

    >.main >.title >div{
      display: block;
      color: $color-black;
      flex: 0 0 80%;
      &:last-child{
        flex: 0 0 20%;
      }
    }
  }
}
