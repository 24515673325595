@import '../../styles/colors';

#RecoveryForm{
  height: 170px;
}

@media screen and (min-width: 768px) {
  #RecoveryForm{

  }
}