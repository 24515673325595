@import '../../styles/colors';

#Button{
  width: 100%;
  transition: .3s ease;
  height: 38px;
  border: none;
  letter-spacing: 1px;
  //font-family: "Roboto";
  text-transform: uppercase;
  border-radius: 8px;
  color: white;
  font-weight: bolder;

  &:hover{
    cursor: pointer;
  }
}

.primary{
  transition: .3s ease;
  background-color: $color-light-primary;

  &:hover{
    box-shadow: 0 5px 10px $color-grey;
  }
}

.secondary{
  transition: .3s ease;
  background-color: white;
  border: 2px solid $color-light-primary !important;
  color: $color-light-text !important;

  &:hover{
    box-shadow: 0 5px 10px $color-grey;
  }
}

.text{
  transition: .2s ease-in-out;
  color: $color-black !important;
  text-transform: none !important;
  //font-family: "Roboto Thin" !important;
  font-weight: 100 !important;
  background-color: transparent;

  &:hover{
    text-decoration: underline;
  }
}

@media (min-width: 576px) {
  #Button{
    max-width: 200px;
  }
}

.fullSize{
  max-width: none !important;
  width: 100%;
}
